exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-index-js": () => import("./../../../src/pages/aviso/index.js" /* webpackChunkName: "component---src-pages-aviso-index-js" */),
  "component---src-pages-aviso-privacidad-referidos-index-js": () => import("./../../../src/pages/avisoPrivacidadReferidos/index.js" /* webpackChunkName: "component---src-pages-aviso-privacidad-referidos-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-promociones-index-js": () => import("./../../../src/pages/promociones/index.js" /* webpackChunkName: "component---src-pages-promociones-index-js" */),
  "component---src-pages-referidos-referidos-js": () => import("./../../../src/pages/referidos/referidos.js" /* webpackChunkName: "component---src-pages-referidos-referidos-js" */),
  "component---src-pages-referidos-status-js": () => import("./../../../src/pages/referidos/status.js" /* webpackChunkName: "component---src-pages-referidos-status-js" */)
}

